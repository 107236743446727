//frontend/src/services/ajusteService.js
import axios from 'axios';

// Configuración de la URL
const API_URL = process.env.REACT_APP_PROD === 'true' 
    ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_LOCAL;

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`
  }
});

// Obtener información del usuario
export const getUserInfo = () => axios.get(`${API_URL}/ajustes/info`, getAuthHeaders());

// Actualizar información del usuario
export const updateUserInfo = (userData) => axios.put(`${API_URL}/ajustes/update`, userData, getAuthHeaders());
