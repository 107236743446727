// frontend/src/services/loansService.js
import axios from 'axios';

// Configuración de la URL según el entorno
const API_URL = process.env.REACT_APP_PROD === 'true' 
    ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_LOCAL;

// Nueva función para verificar protección
export const verifyProtected = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('No token found');
        }
        const response = await axios.get(`${API_URL}/protected`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error al verificar protección:', error);
        throw error;
    }
};

export const registerLoan = async (loan) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Token no encontrado');
    }
    console.log("Token enviado:", token);

    try {
        return await axios.post(`${API_URL}/loans`, {
            ...loan,
            id_ejemplar: loan.id_ejemplar // Asegúrate de que sea id_ejemplar
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    } catch (error) {
        if (error.response) {
            // Errores HTTP específicos
            console.error(`Error al registrar préstamo: ${error.response.status} - ${error.response.data}`);
        } else {
            console.error('Error desconocido al registrar préstamo:', error);
        }
        throw error;
    }
};

export const getLoans = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Token no encontrado');
    }
    try {
        return await axios.get(`${API_URL}/loans`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
    } catch (error) {
        if (error.response) {
            // Errores HTTP específicos
            console.error(`Error al obtener préstamos: ${error.response.status} - ${error.response.data}`);
        } else {
            console.error('Error desconocido al obtener préstamos:', error);
        }
        throw error;
    }
};