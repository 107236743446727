// frontend/src/components/Books.js
import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { getBooks, addBook, updateBook, deleteBook, checkAvailability, getLanguages, getLevels, getCategorias, getAuthors } from '../services/booksService';
import './Books.css';

const Books = () => {
  const [books, setBooks] = useState([]);
  const [levels, setLevels] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [newBook, setNewBook] = useState({
    nombre: '',
    editorial: '',
    year_publicacion: '',
    cantidad: '',
    id_nivel_educativo: '',
    id_idioma: '',
    id_categoria: '',
    id_autores: []
  });
  const [newAuthor, setNewAuthor] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editBookId, setEditBookId] = useState(null);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('Token no encontrado, redirigiendo al inicio de sesión');
      window.location.href = '/login';
    } else {
      try {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.rol);
        console.log('Rol del usuario:', decodedToken.rol);
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }

      const fetchData = async () => {
        try {
          await Promise.all([
            fetchBooks(),
            fetchLevels(),
            fetchLanguages(),
            fetchCategories(),
            fetchAuthors(),
          ]);
        } catch (error) {
          console.error('Error al cargar datos iniciales:', error);
        }
      };
      fetchData();
    }
  }, []);

  const fetchBooks = async () => {
    try {
      const response = await getBooks();
      setBooks(response.data);
    } catch (error) {
      console.error('Error al obtener libros:', error);
    }
  };

  const fetchLevels = async () => {
    try {
      const response = await getLevels();
      setLevels(response.data);
    } catch (error) {
      console.error('Error al obtener niveles educativos:', error);
    }
  };

  const fetchLanguages = async () => {
    try {
      const response = await getLanguages();
      setLanguages(response.data);
    } catch (error) {
      console.error('Error al obtener idiomas:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getCategorias();
      setCategories(response.data);
    } catch (error) {
      console.error('Error al obtener categorías:', error);
    }
  };

  const fetchAuthors = async () => {
    try {
      const response = await getAuthors();
      setAuthors(response.data);
    } catch (error) {
      console.error('Error al obtener autores:', error);
    }
  };

  const handleAddOrUpdateBook = async () => {
    if (userRole !== 'Bibliotecaria') {
      alert('No tienes permisos para realizar esta acción');
      return;
    }

    const authorIds = newBook.id_autores.map((name) => {
      const author = authors.find((a) => a.nombre === name);
      return author ? author.id_autor : null;
    });

    if (authorIds.includes(null)) {
      alert('Uno o más autores no son válidos');
      return;
    }

    try {
      if (editMode) {
        await updateBook(editBookId, { ...newBook, id_autores: authorIds });
      } else {
        await addBook({ ...newBook, id_autores: authorIds });
      }
      setNewBook({ nombre: '', editorial: '', year_publicacion: '', cantidad: '', id_nivel_educativo: '', id_idioma: '', id_categoria: '', id_autores: [] });
      setEditMode(false);
      fetchBooks();
    } catch (error) {
      console.error('Error registrando libro:', error);
    }
  };

  const handleEditBook = (book) => {
    if (userRole !== 'Bibliotecaria') {
      alert('No tienes permisos para editar libros');
      return;
    }

    setNewBook({
      nombre: book.nombre,
      editorial: book.editorial,
      year_publicacion: book.year_publicacion,
      cantidad: book.cantidad,
      id_nivel_educativo: book.id_nivel_educativo,
      id_idioma: book.id_idioma,
      id_categoria: book.id_categoria,
      id_autores: book.autores || []
    });
    setEditMode(true);
    setEditBookId(book.id_libro);
  };

  const handleDeleteBook = async (id) => {
    if (userRole !== 'Bibliotecaria') {
      alert('No tienes permisos para eliminar libros');
      return;
    }

    try {
      await deleteBook(id);
      fetchBooks();
    } catch (error) {
      console.error('Error al eliminar el libro:', error);
    }
  };

  const handleCheckAvailability = async (id) => {
    try {
      const response = await checkAvailability(id);
      const disponibilidad = response.data.disponible ? 'Disponible' : 'Prestado';

      setBooks((prevBooks) =>
        prevBooks.map((book) =>
          book.id_libro === id ? { ...book, disponibilidad } : book
        )
      );
    } catch (error) {
      console.error('Error al verificar disponibilidad:', error);
    }
  };

  return (
    <div className="container">
      <h2 className="title">Gestión de Libros</h2>
      {userRole === 'Bibliotecaria' && (
        <div className="form-container">
          <input type="text" placeholder="Nombre del libro" value={newBook.nombre || ''} onChange={(e) => setNewBook({ ...newBook, nombre: e.target.value })} />
          <input type="text" placeholder="Editorial" value={newBook.editorial || ''} onChange={(e) => setNewBook({ ...newBook, editorial: e.target.value })} />
          <input type="number" placeholder="Año de publicación" value={newBook.year_publicacion || ''} onChange={(e) => setNewBook({ ...newBook, year_publicacion: e.target.value })} />
          <input type="number" placeholder="Cantidad" value={newBook.cantidad || ''} onChange={(e) => setNewBook({ ...newBook, cantidad: e.target.value })} />

          <select value={newBook.id_nivel_educativo || ''} onChange={(e) => setNewBook({ ...newBook, id_nivel_educativo: e.target.value })}>
            <option value="">Seleccione un nivel educativo</option>
            {levels.map((level) => (
              <option key={level.id_nivel_educativo} value={level.id_nivel_educativo}>{level.nombre_nivel}</option>
            ))}
          </select>

          <select value={newBook.id_idioma || ''} onChange={(e) => setNewBook({ ...newBook, id_idioma: e.target.value })}>
            <option value="">Seleccione un idioma</option>
            {languages.map((language) => (
              <option key={language.id_idioma} value={language.id_idioma}>{language.nombre}</option>
            ))}
          </select>

          <select value={newBook.id_categoria || ''} onChange={(e) => setNewBook({ ...newBook, id_categoria: e.target.value })}>
            <option value="">Seleccione una categoría</option>
            {categories.map((category) => (
              <option key={category.id_categoria} value={category.id_categoria}>{category.nombre_categoria}</option>
            ))}
          </select>

          <input
            type="text"
            placeholder="Nombre de autor (nuevo)"
            value={newAuthor}
            onChange={(e) => setNewAuthor(e.target.value)}
          />

          <select
            multiple
            value={newBook.id_autores || []}
            onChange={(e) => {
              const options = e.target.options;
              const selectedAuthors = [];
              for (let i = 0; i < options.length; i++) {
                if (options[i].selected) {
                  selectedAuthors.push(options[i].value);
                }
              }
              setNewBook({ ...newBook, id_autores: selectedAuthors });
            }}
          >
            <option value="">Seleccione autores</option>
            {authors.map((author) => (
              <option key={author.id_autor} value={author.id_autor}>
                {author.nombre} {author.apellido}
              </option>
            ))}
          </select>

          <button className='btn-accion' onClick={handleAddOrUpdateBook}>{editMode ? 'Actualizar Libro' : 'Agregar Libro'}</button>
          {editMode && (
            <button className='btn-accion' onClick={() => {
              setEditMode(false);
              setNewBook({ nombre: '', editorial: '', year_publicacion: '', cantidad: '', id_nivel_educativo: '', id_idioma: '', id_categoria: '', id_autor: '' });
            }}>Cancelar Edición</button>
          )}
        </div>
      )}

      <ul className="books-list">
        {books.map((book) => (
          <li key={book.id_libro} className="book-item">
            <strong>{book.nombre}</strong> - {book.editorial} ({book.year_publicacion}) <br />
            <span>Cantidad: {book.cantidad}</span> <br />
            <span>Nivel Educativo: {book.nivel_educativo || 'No especificado'}</span> <br />
            <span>Idioma: {book.idioma || 'No especificado'}</span> <br />
            <span>Categoría: {book.categoria || 'No especificada'}</span> <br />
            <span>Autor(es): {book.autores || 'No especificados'}</span> <br />
            {userRole === 'Bibliotecaria' && (
              <div>
                <button className='btn-accion' onClick={() => handleEditBook(book)}>Editar</button>
                <button className='btn-accion' onClick={() => handleDeleteBook(book.id_libro)}>Eliminar</button>
              </div>
            )}
            <button className='btn-accion' onClick={() => handleCheckAvailability(book.id_libro)}>Ver Disponibilidad</button>
            {book.disponibilidad && <p>Disponibilidad: {book.disponibilidad}</p>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Books;