// frontend/src/components/Loans.js
import React, { useState, useEffect } from 'react';
import { registerLoan, getLoans } from '../services/loansService';
import {jwtDecode} from 'jwt-decode';
import './Loans.css';

const Loans = () => {
  const [loan, setLoan] = useState({ id_ejemplar: '', id_usuario: '', fecha_prestamo: '' });
  const [loansList, setLoansList] = useState([]);
  //const [userRole, setUserRole] = useState(''); // Estado para el rol del usuario
  const [loading, setLoading] = useState(true); // Estado de carga

  /*useEffect(() => {
    fetchUserRole(); // Obtener el rol del usuario
    loadLoans(); // Cargar la lista de préstamos
  }, []);*/

  /*const fetchUserRole = async () => {
    try {
      const response = await fetch('/api/user/role', {
        method: 'GET', // Método de la petición
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Error al obtener el rol del usuario');
      }
      const data = await response.json();
      setUserRole(data.role);
    } catch (error) {
      console.error('Error al obtener el rol del usuario:', error);
    } finally {
      setLoading(false); // Termina de cargar
    }
  };*/

  useEffect(() => {
    const initializeComponent = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.log('No token found');
          window.location.href = '/login';
          return;
        }

        // Decode token to get user role
        const decoded = jwtDecode(token);
        //setUserRole(decoded.rol);
        console.log('User role:', decoded.rol);

        // Load loans
        await loadLoans();
      } catch (error) {
        console.error('Error initializing component:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeComponent();
  }, []);

  const loadLoans = async () => {
    try {
      const { data } = await getLoans();
      console.log('Loans data received:', data); // Add this line to debug
      setLoansList(data);
    } catch (error) {
      console.error('Error al cargar los préstamos:', error);
    }
  };

  const handleRegisterLoan = async () => {
    if (!loan.id_ejemplar || !loan.id_usuario || !loan.fecha_prestamo) {
      alert('Por favor completa todos los campos');
      return;
    }

    const loanData = {
      id_ejemplar: parseInt(loan.id_ejemplar),
      id_usuario: parseInt(loan.id_usuario),
      fecha_prestamo: loan.fecha_prestamo,
    };

    try {
      await registerLoan(loanData);
      alert('Préstamo registrado con éxito');
      loadLoans(); // Recargar la lista de préstamos después de registrar
    } catch (error) {
      console.error('Error al registrar el préstamo:', error);
      alert('Error al registrar el préstamo');
    }
  };

  if (loading) {
    return <div>Cargando...</div>; // Mostrar cargando mientras obtenemos el rol
  }

  return (
    <div className="loans-container">
      <h1>Gestión de Préstamos</h1>

      <div className="form-container">
        <input
          type="number"
          placeholder="ID Ejemplar"
          value={loan.id_ejemplar}
          onChange={(e) => setLoan({ ...loan, id_ejemplar: e.target.value })}
        />
        <input
          type="number"
          placeholder="ID Usuario"
          value={loan.id_usuario}
          onChange={(e) => setLoan({ ...loan, id_usuario: e.target.value })}
        />
        <input
          type="date"
          value={loan.fecha_prestamo}
          onChange={(e) => setLoan({ ...loan, fecha_prestamo: e.target.value })}
        />
        <button className='btn-register' onClick={handleRegisterLoan}>Registrar Préstamo</button>
      </div>


      <h2>Lista de Préstamos</h2>
      <table className="loans-table">
        <thead>
          <tr>
            <th>ID Préstamo</th>
            <th>ID Ejemplar</th>
            <th>Nombre Libro</th>
            <th>Usuario</th>
            <th>DNI</th>
            {/*<th>Grado Académico</th>*/}
            {/*<th>Sección</th>*/}
            <th>Fecha Préstamo</th>
          </tr>
        </thead>
        <tbody>
          {loansList.map((loan) => {
            console.log('Rendering loan:', loan); // Add this line to debug
            return (
              <tr key={loan.id_prestamo}>
                <td>{loan.id_prestamo}</td>
                <td>{loan.id_ejemplar}</td>
                <td>{loan.nombre_libro}</td>
                <td>{loan.nombre_usuario}</td>
                <td>{loan.id_usuario}</td>
                {/*<td>{loan.grado_academico || 'No asignado'}</td>*/}
                {/*<td>{loan.seccion || 'No asignada'}</td>*/}
                <td>{new Date(loan.fecha_prestamo).toLocaleDateString()}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Loans;