// frontend/src/services/returnsService.js
import axios from 'axios';

// Configuración de la URL según el entorno
const API_URL = process.env.REACT_APP_PROD === 'true' 
    ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_LOCAL;

// Nueva función para verificar protección
export const verifyProtected = async () => {
    try {
        const response = await axios.get(`${API_URL}/protected`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error al verificar protección:', error);
        throw error;
    }
};

export const registerReturn = async (returnData) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found');
  
    console.log('Datos enviados al backend:', returnData);
    
    try {
      const response = await axios.post(`${API_URL}/returns`, returnData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Respuesta del backend:', response);
      return response;
    } catch (error) {
      console.error('Error completo:', error.response || error);
      throw error;
    }
  };

export const getReturns = async () => {
    try {
        return await axios.get(`${API_URL}/returns`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
    } catch (error) {
        console.error('Error al obtener devoluciones:', error);
        throw error;
    }
};