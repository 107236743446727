//frontend/src/services/booksService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_PROD === 'true' 
    ? process.env.REACT_APP_API_URL_PROD 
    : process.env.REACT_APP_API_URL_LOCAL;

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`
  }
});

export const getBooks = () => axios.get(`${API_URL}/books`, getAuthHeaders());
export const addBook = (book) => axios.post(`${API_URL}/books`, book, getAuthHeaders());
export const updateBook = (id, book) => axios.put(`${API_URL}/books/${id}`, book, getAuthHeaders());
export const deleteBook = (id) => axios.delete(`${API_URL}/books/${id}`, getAuthHeaders());
export const checkAvailability = (id) => axios.get(`${API_URL}/books/${id}/availability`, getAuthHeaders());
export const getCategorias = () => axios.get(`${API_URL}/books/categorias`, getAuthHeaders());
export const getLanguages = () => axios.get(`${API_URL}/books/languages`, getAuthHeaders());
export const getLevels = () => axios.get(`${API_URL}/books/levels`, getAuthHeaders());
export const getAuthors = () => axios.get(`${API_URL}/books/authors`, getAuthHeaders());