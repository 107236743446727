// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ajustes-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-left: 250px;
  }
  
  .ajustes-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .ajustes-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .ajustes-container input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .ajustes-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .ajustes-container button:hover {
    background-color: #0056b3;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Ajustes.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".ajustes-container {\n    max-width: 500px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    margin-left: 250px;\n  }\n  \n  .ajustes-container h2 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .ajustes-container form {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n  }\n  \n  .ajustes-container input {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  .ajustes-container button {\n    padding: 10px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    font-weight: bold;\n  }\n  \n  .ajustes-container button:hover {\n    background-color: #0056b3;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
