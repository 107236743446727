import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faGear } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const API_URL =
  process.env.REACT_APP_PROD === "true"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_LOCAL;

const Login = ({ setAuth }) => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Redirige si el usuario ya está autenticado
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/books");
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!identifier || !password) {
      setError("Por favor, completa todos los campos.");
      return;
    }

    setError("");

    try {
      console.log("Datos enviados:", { correo: identifier, password: password });
      const response = await axios.post(`${API_URL}/auth/login`, {
        correo: identifier,
        password: password,
      });

      console.log("Token recibido:", response.data.token);
      localStorage.setItem("token", response.data.token);
      setAuth(true);
      navigate("/books");
    } catch (err) {
      console.error("Error en la solicitud:", err.response ? err.response.data : err.message);
      setError(err.response ? err.response.data.message : err.message);
    }
  };

  return (
    <div className="container-login">
      <div className="login">
        <div className="login-gear">
          <FontAwesomeIcon icon={faGear} className="gear-icon" />
        </div>
        <div className="login-logo">
          <img src="./images/logo-hdac-trans.png" alt="Logo" className="logo-hdac" />
        </div>
        <div className="login-main">
          <p className="login-main-title">Bienvenido</p>
          <form onSubmit={handleLogin} className="login-main-input">
            <div className="input-div-identifier">
              <FontAwesomeIcon icon={faUser} className="icon-identifier" />
              <input
                className="input-identifier"
                type="text"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                placeholder="Correo o DNI"
                autoFocus />
            </div>
            <div className="input-div-password">
              <FontAwesomeIcon icon={faLock} className="icon-password" />
              <input
                className="input-password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Contraseña" />
            </div>
            <button className="input-button">Ingresar</button>
            {error && <p className="login-message-error">{error}</p>}
            <p className="a-recover-password">Olvidó su contraseña?</p>
          </form>
        </div>
      </div>
      <div className="hero">
      </div>
    </div>
  );
};

export default Login;