/*frontend/src/components/Returns.js*/
import React, { useState, useEffect } from 'react';
import { registerReturn, getReturns } from '../services/returnsService';
import { jwtDecode } from 'jwt-decode';
import './Returns.css';

const Returns = () => {
  const [returnData, setReturnData] = useState({ id_prestamo: '', fecha_devolucion: '' });
  const [returnsList, setReturnsList] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initComponent = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          return;
        }
  
        const decoded = jwtDecode(token);
        console.log('Role:', decoded.rol);
        setUserRole(decoded.rol);
  
        const { data } = await getReturns();
        console.log('Returns data:', data);
        setReturnsList(data);
      } catch (error) {
        console.error('Error loading returns:', error);
      } finally {
        setLoading(false);
      }
    };
  
    initComponent();
  }, []);

  const handleRegisterReturn = async () => {
    if (!returnData.id_prestamo || !returnData.fecha_devolucion) {
      alert('Por favor complete todos los campos');
      return;
    }
  
    try {
      // Log data being sent
      console.log('Sending return data:', returnData);
  
      const response = await registerReturn({
        id_prestamo: parseInt(returnData.id_prestamo),
        fecha_devolucion: returnData.fecha_devolucion
      });
  
      console.log('Return response:', response);
  
      if (response.status === 200) {
        alert('Devolución registrada exitosamente');
        const { data } = await getReturns();
        setReturnsList(data);
        setReturnData({ id_prestamo: '', fecha_devolucion: '' });
      }
    } catch (error) {
      console.error('Error detallado:', error.response?.data || error);
      alert(error.response?.data?.message || 'Error al registrar la devolución');
    }
  };

  if (loading) return <div>Cargando...</div>;

  return (
    <div className="returns-container">
      <h1>Gestión de Devoluciones</h1>
      {userRole === 'Bibliotecaria' && (
        <div className="form-container">
          <input
            type="text"
            min="1"
            placeholder="ID Préstamo"
            value={returnData.id_prestamo}
            onChange={(e) => setReturnData({ ...returnData, id_prestamo: e.target.value })}
            className="form-input"
          />
          <input
            type="date"
            value={returnData.fecha_devolucion}
            onChange={(e) => setReturnData({ ...returnData, fecha_devolucion: e.target.value })}
            className="form-input"
          />
          <button className="btn-dev" onClick={handleRegisterReturn}>Registrar Devolución</button>
        </div>
      )}
      <table className="returns-table">
        <thead>
          <tr>
            <th>ID Préstamo</th>
            <th>ID Ejemplar</th>
            <th>Libro</th>
            {/*<th>Usuario</th>*/}
            <th>DNI</th>
            <th>Fecha Préstamo</th>
            <th>Fecha Devolución</th>
          </tr>
        </thead>
        <tbody>
          {returnsList.map((item) => (
            <tr key={item.id_prestamo}>
              <td>{item.id_prestamo}</td>
              <td>{item.id_ejemplar}</td>
              <td>{item.nombre_libro}</td>
              {/*<td>{item.nombre_usuario}</td>*/}
              <td>{item.id_usuario}</td>
              <td>{new Date(item.fecha_prestamo).toLocaleDateString()}</td>
              <td>{new Date(item.fecha_devolucion).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Returns;