//frontend/Src/components/History.js
import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Corregir la importación
import historyService from '../services/historyService';
import './History.css';

const History = () => {
    const [history, setHistory] = useState([]);
    const [userRole, setUserRole] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initializeComponent = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    window.location.href = '/login';
                    return;
                }

                const decoded = jwtDecode(token); // Utilizar la función correctamente
                setUserRole(decoded.rol);

                const data = await historyService.getHistory();
                setHistory(data);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        initializeComponent();
    }, []);

    if (loading) return <div>Cargando...</div>;

    return (
        <div className="history-container">
            <h1>Historial de Préstamos</h1>
            {history.length > 0 ? (
                <table className="history-table">
                    <thead>
                        <tr>
                            <th>ID Préstamo</th>
                            <th>Código</th>
                            <th>Título</th>
                            {userRole === 'Bibliotecaria' && <th>Usuario</th>}
                            {userRole === 'Bibliotecaria' && <th>DNI</th>}
                            <th>F. Préstamo</th>
                            <th>F. Devolución</th>
                            <th>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {history.map((item) => (
                            <tr key={item.id_prestamo}>
                                <td>{item.id_prestamo}</td>
                                <td>{item.codigo_ejemplar}</td>
                                <td>{item.titulo}</td>
                                {userRole === 'Bibliotecaria' && <td>{item.nombre_usuario}</td>}
                                {userRole === 'Bibliotecaria' && <td>{item.dni}</td>}
                                <td>{item.fecha_prestamo}</td>
                                <td>{item.fecha_devolucion || '-'}</td>
                                <td className={`status ${item.estado_prestamo.toLowerCase()}`}>
                                    {item.estado_prestamo}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No hay registros disponibles.</p>
            )}
        </div>
    );
};

export default History;
