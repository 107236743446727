//frontend/src/components/Ajustes.js
import React, { useState, useEffect } from 'react';
import { getUserInfo, updateUserInfo } from '../services/ajusteService';
import './Ajustes.css';

const Ajustes = () => {
  const [userInfo, setUserInfo] = useState({
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
    correo: '',
    telefono: '',
    id_grado: '',
    id_seccion: '',
    id_nivel_educativo: ''
  });

  // Función para cargar la información del usuario
  const loadUserInfo = async () => {
    try {
      const { data } = await getUserInfo(); // Llamada a la API
      console.log("Datos cargados desde la API:", data); // Verificar en consola
      setUserInfo({
        nombre: data.nombre || '',
        apellido_paterno: data.apellido_paterno || '',
        apellido_materno: data.apellido_materno || '',
        correo: data.correo || '',
        telefono: data.telefono || '',
        id_grado: data.id_grado || '',
        id_seccion: data.id_seccion || '',
        id_nivel_educativo: data.id_nivel_educativo || ''
      });
    } catch (error) {
      console.error('Error al obtener la información del usuario:', error);
    }
  };

  // Ejecutar la función al montar el componente
  useEffect(() => {
    loadUserInfo();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserInfo(userInfo);
      alert('Información actualizada con éxito');
    } catch (error) {
      console.error('Error al actualizar la información del usuario:', error);
      alert('Hubo un error al actualizar la información');
    }
  };

  return (
    <div className="ajustes-container">
      <h2>Ajustes de Usuario</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="nombre" value={userInfo.nombre} onChange={handleInputChange} placeholder="Nombre" />
        <input type="text" name="apellido_paterno" value={userInfo.apellido_paterno} onChange={handleInputChange} placeholder="Apellido Paterno" />
        <input type="text" name="apellido_materno" value={userInfo.apellido_materno} onChange={handleInputChange} placeholder="Apellido Materno" />
        <input type="email" name="correo" value={userInfo.correo} onChange={handleInputChange} placeholder="Correo" />
        <input type="text" name="telefono" value={userInfo.telefono} onChange={handleInputChange} placeholder="Teléfono" />
        <input type="number" name="id_grado" value={userInfo.id_grado} onChange={handleInputChange} placeholder="ID Grado" />
        <input type="number" name="id_seccion" value={userInfo.id_seccion} onChange={handleInputChange} placeholder="ID Sección" />
        <input type="number" name="id_nivel_educativo" value={userInfo.id_nivel_educativo} onChange={handleInputChange} placeholder="ID Nivel Educativo" />
        <button type="submit">Actualizar Información</button>
      </form>
    </div>
  );
};

export default Ajustes;
 
