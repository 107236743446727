// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-container {
  padding: 20px;
  margin-left: 250px;
  max-width: calc(100% - 270px);
  font-family: Arial, sans-serif;
}

.history-container h1 {
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
}

.history-table th,
.history-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.history-table th {
  background-color: #f4f4f4;
  font-weight: 600;
  color: #333;
}

.history-table tr:hover {
  background-color: #f5f5f5;
}

.status {
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
}

.status.prestado {
  background-color: #e3f2fd;
  color: #1976d2;
}

.status.devuelto {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status.atrasado {
  background-color: #ffebee;
  color: #c62828;
}

@media (max-width: 768px) {
  .history-container {
    margin-left: 0;
    max-width: 100%;
    padding: 10px;
  }

  .history-table {
    display: block;
    overflow-x: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/History.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,iBAAiB;EACjB,qCAAqC;EACrC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;;EAEE,kBAAkB;EAClB,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE;IACE,cAAc;IACd,eAAe;IACf,aAAa;EACf;;EAEA;IACE,cAAc;IACd,gBAAgB;EAClB;AACF","sourcesContent":[".history-container {\n  padding: 20px;\n  margin-left: 250px;\n  max-width: calc(100% - 270px);\n  font-family: Arial, sans-serif;\n}\n\n.history-container h1 {\n  color: #333;\n  margin-bottom: 30px;\n  font-size: 24px;\n}\n\n.history-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n  background: white;\n  box-shadow: 0 1px 3px rgba(0,0,0,0.1);\n  border-radius: 8px;\n  overflow: hidden;\n}\n\n.history-table th,\n.history-table td {\n  padding: 12px 15px;\n  text-align: left;\n  border-bottom: 1px solid #ddd;\n}\n\n.history-table th {\n  background-color: #f4f4f4;\n  font-weight: 600;\n  color: #333;\n}\n\n.history-table tr:hover {\n  background-color: #f5f5f5;\n}\n\n.status {\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-weight: 500;\n  text-align: center;\n}\n\n.status.prestado {\n  background-color: #e3f2fd;\n  color: #1976d2;\n}\n\n.status.devuelto {\n  background-color: #e8f5e9;\n  color: #2e7d32;\n}\n\n.status.atrasado {\n  background-color: #ffebee;\n  color: #c62828;\n}\n\n@media (max-width: 768px) {\n  .history-container {\n    margin-left: 0;\n    max-width: 100%;\n    padding: 10px;\n  }\n\n  .history-table {\n    display: block;\n    overflow-x: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
